import React from "react"
// import { Link } from "gatsby"

// import Image from "../components/image"

const FurtherReading = props => (
  <div
    style={{
      // backgroundColor: "#FFFAFA",
      border: "1px solid 	#DCDCDC",
      maxWidth: `900px`,
      marginBottom: `1.45rem`,
      padding: "10px",
      borderRadius: "5px",
      // boxShadow: "2px 2px 3px #D3D3D3",
    }}
  >
    <h3 style={{ fontSize: "1.2rem", paddingTop: "10px" }}>
      You might also be interested in...
    </h3>
    <div>{props.content}</div>
  </div>
)

export default FurtherReading
