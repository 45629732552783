import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const AfroHairTshirts = () => (
  <Layout>
    <SEO
      title="Afro Hair T-Shirts"
      description="Beautiful Natural Afro Hair themed t-shirt designs. Comfortable and made to be something you can be proud of. Show everyone that you are part of the natural hair community"
    />
    <h1>Afro Hair T-Shirts</h1>
    <h2 style={{color:"Orchid"}}>Beautiful Natural Afro Hair themed t-shirt designs</h2>
    <p>Hi guys, we decided to expand the brand by releasing a few designs for NAHC. If you read our about page, you know that clothing is something we are passionate about. We don't want to go all out and release a whole line just yet so we are working on t-shirts for now.</p>
  
    <h2>Why T-shirts?</h2>
    <p>We want to keep it simple to begin with while still giving you something to be proud of. T-Shirts are accessible to everyone in that you likely know exactly what size you, if you don't then there's a measuring guide you can check out in the store.</p>
   
    <h2>Our Afro T-Shirt designs</h2>
    <p>Our designs are inspired by what we see in our own business and around the afro/ natural hair community. We worked in-house to come up with the initial logos and illustrations. Then we collaborated with professional graphic designers to get the images print ready specifically for clothing.</p>
    <p>You wouldn't believe what difference it makes to speak to a professional. We learned so much about readability from a distance. Printing and embroidery options. Fabric options for weight and quality. How all this affects production time and cost. It was an amazing experience and we can't wait for you to get our products.</p>

    <h2>NAHC Brand Design Philosophy</h2>
    <p>You've read about our inspiration so you know why we are making the clothing. We thought it would be a good idea to make a list of design rules we just stick to as well. Just like how we designed the site to be neat, clutter free and fast to give an amazing user experience, guidance for our clothing gives us direction and purpose. It gives us something we can look back at from time to time so we can make sure we are staying true to our plan.</p>

    <h2>Here are the exact design principles;</h2>
    
    <p><strong>Community</strong> - Each piece must be inspired by something in the natural hair community. It should have a clear story for why it exists and the piece should resonate with the buyer for more than just aesthetic reasons. It should give a sense of pride in whoever wears it because it connects us to our core beliefs and gives other people a hint of what we are about.</p>
    <p>It should be an expression of who we are without having to say a word, whether it be a bright statement piece or comfortable lounge wear.</p>

    <p><strong>Simple</strong> - The fact that you are wearing a NAHC piece should come across immediately. From our signature colours to our unique logo or confident message. Simple doesn't have to mean boring. Simple in our case means calm and confidence in the design presented.</p>

    <p><strong>Timeless</strong> - We want our pieces to last, you should be able to wear them with pride over and over again. Even pass them down to the next generation. Pride in our natural hair isn't a temporary movement. It isn't a fad, it is something that is here to stay and our brand will be a reminder. When you put on your NAHC apparel you will be reminded of this every time.</p>

    <h2>How it works</h2>
    <p>We currently use a print on demand service. They hold our design for us and when you make a purchase, they handle printing and shipping. This lets us focus on other parts of the business like coming up with designs and acting on feedback.</p>

    <h2>Do you need to worry about quality?</h2>
    <p>No. Even though printing and shipping is handled externally, we still do our own quality control to go along with theirs. They are a trusted service and for our own peace of mind, we order random samples to make sure our customers are getting something we are happy with.</p>

    <h2>Discounts</h2>
    <p>We all love a good discount. If we have a promotion, it won't be too hard to find. It will either be somewhere on our site or one our social media account. If you can't find one and really want it then get in touch and we might just be able to help you out.</p>

    <h2>Future plans</h2>
    <p>When we have a number of designs that we know you guys like, we will move away from the printing service and stock our own product in bulk. This will allow us to work with different manufacturers, ship with custom packaging, expand our product range and many other benefits.</p>



      {/* <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/1wFIPZWl.jpg"
        alt="how to get an afro nahc"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@princearkman?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Prince Akachi</a></p>
    </div> */}


      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>                  
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default AfroHairTshirts
